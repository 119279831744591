import { Chess } from "chess.js";
import { createContext } from "react";

//const chess = new Chess(); //chess object causing double calls? Reducer not pure
const updateState = (state) => {
  const chess = new Chess(state.fen);
  const newState = {
    ...state,
    board: chess.board(),
    fen: chess.fen(), // `rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1`,
    turn: chess.turn() === "w" ? "white" : "black",
    validMoves: chess.moves({ verbose: true }),
    getPosition: chess.get,
    activeStates: {
      game_over: chess.game_over(),
      check: chess.in_check(),
      checkmate: chess.in_checkmate(),
      stalemate: chess.in_stalemate(),
      insufficient_material: chess.insufficient_material(),
      threefold_repetition: chess.in_threefold_repetition(),
      draw: chess.in_draw(),
    },
  };
  return newState;
};
export const initialState = updateState({
  fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  history: [],
  playerColour: "white",
  lastMove: null,
  highlightedPositions: [],
  difficulty: 2,
  selectedPosition: null,
});
export const action = {
  RESET: "RESET",
  SET_DIFFICULTY: "SET_DIFFICULTY",
  SET_PLAYER_COLOUR: "SET_PLAYER_COLOUR",
  SELECT_POSITION: "SELECT_POSITION",
  TAKE_BACK: "TAKE_BACK",
  MOVE: "MOVE",
};

export function gameReducer(state, action) {
  const chess = new Chess(state.fen);
  switch (action.type) {
    case "RESET":
      return { ...initialState, playerColour: state.playerColour, difficulty: state.difficulty };
    case "SET_DIFFICULTY":
      return { ...state, difficulty: +action.difficulty };
    case "SET_PLAYER_COLOUR":
      return { ...state, playerColour: action.playerColour };
    case "SELECT_POSITION":
      return {
        ...state,
        selectedPosition: action.position,
        highlightedPositions: chess.moves({ square: action.position, verbose: true }).map((move) => move.to),
      };
    case "TAKE_BACK":
      chess.reset();
      state.history.forEach((move) => chess.move(move));
      chess.undo(); //undo until it's players turn again
      if (chess.turn() !== state.playerColour[0]) {
        chess.undo();
      }
      return updateState({
        ...state,
        fen: chess.fen(),
        selectedPosition: null,
        highlightedPositions: [],
        history: chess.history({ verbose: true }),
        lastMove: chess.history({ verbose: true })[chess.history().length - 1],
      });
    case "MOVE":
      const move = chess.move(action.move);
      if (!move) {
        return state;
      }
      return updateState({
        ...state,
        fen: chess.fen(),
        selectedPosition: null,
        highlightedPositions: [],
        history: state.history.concat([move]),
        lastMove: move,
      });
    default:
      throw Error("Unknown action: " + action);
  }
}

export const GameContext = createContext(null);
export const GameDispatchContext = createContext(null);
