import chessSet from "assets/chesssets/neo/set";
import styled from "styled-components";

const StyledPiece = styled.img`
  width: 90%;
  height: 90%;
  margin: 5%;
  z-index: 5;
  pointer-events: none;
  grid-area: ${(props) => props.gridArea};
`;
const Piece = (props) => {
  const { type, color } = props;
  const sprite = chessSet[`${color}${type.toUpperCase()}`];
  return (
    <StyledPiece
      {...props}
      ref={props.forwardRef}
      src={sprite}
      alt={`${color}${type.toUpperCase()}`}
      draggable="true"
    />
  );
};

export default Piece;
