import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --header-height: 64px;
    --board-size: min(calc(100vh - var(--header-height)), 100vw ); //also needs to be updated in Layout.js

    @media
      only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (   min--moz-device-pixel-ratio: 2),
      only screen and (     -o-min-device-pixel-ratio: 2/1),
      only screen and (        min-device-pixel-ratio: 2),
      only screen and (                min-resolution: 192dpi),
      only screen and (                min-resolution: 2dppx) {
        /* Retina styles here */
        --board-size: min(calc(100vh - var(--header-height)), 100vw ); //also needs to be updated in Layout.js
    }
  }
`;
export default GlobalStyle;
