import Styled from "styled-components";
const StyleMoveList = Styled.ol`
    list-style-type: none;
    padding: 0;
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      background: #f0f0f0;
      div:first-child {
        width: 3em;
      }
    }
    li:nth-child(odd) { background: #e0e0e0; }
  `;

const Move = ({ move }) => {
  const isCapture = move.flags.includes("c");
  const isCheck = move.san.includes("+");
  const isCheckmate = move.san.includes("#");

  return (
    <StyledMove isCapture={isCapture} isCheck={isCheck} isCheckmate={isCheckmate}>
      {move.san}
    </StyledMove>
  );
};
const StyledMove = Styled.div`
    width: calc(100% / 3);
    padding-left: 0.5em;
    text-align: left;

    ${(props) => props.isCheck && "color: red;"}
    ${(props) => props.isCheckmate && "color: red;\n font-weight: bold;"}
    ${(props) => props.isCapture && "font-weight: bold;"}
  `;

export default function MoveList(props) {
  const moveList = [];
  const moves = props.moves;
  for (let i = 0; i < moves.length; i += 2) {
    let white_move = moves[i];
    let black_move = moves.length > i + 1 && moves[i + 1];
    moveList.push(
      <li key={i / 2}>
        <div>{i / 2 + 1}.</div>
        <Move move={white_move} />
        {black_move && <Move move={black_move} />}
      </li>
    );
  }
  return <StyleMoveList {...props}>{moveList}</StyleMoveList>;
}
