import { useContext } from "react";
import { GameContext } from "state/GameState";
import styled from "styled-components";
import MoveList from "./MoveList";

const StyledGameInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 216px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "gutter move_list padding";
  height: 100%;
`;
export default function GameInfo({ style }) {
  const game = useContext(GameContext);

  return (
    <StyledGameInfo style={style}>
      <MoveList style={{ gridArea: "move_list" }} moves={game.history} />
    </StyledGameInfo>
  );
}
