import styled from "styled-components";

const StyledGutter = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
  grid-area: ${(props) => props.gridArea};
  background-color: rgba(0, 0, 0, 0.5);
  color: #ccc;
  color: rgba(238, 238, 238, 0.9);
  font-weight: bold;

  @media (max-width: calc(240px + min(calc(100vh - 64px), 100vw ) + 240px)) {
    //can't use --board-size here as media queries cannot use css variables
    display: none;
    grid-template-columns: 0 repeat(8, 1fr) 0;
    grid-template-rows: 0 repeat(8, 1fr) 0;
    overflow: hidden;
  }
  /* border-left: ${(props) => props.position === "right" && "2px solid black"};
  border-right: ${(props) => props.position === "left" && "2px solid black"};
  border-top: ${(props) => props.position === "bottom" && "2px solid black"};
  border-bottom: ${(props) => props.position === "top" && "2px solid black"}; */
`;
const Gutter = (props) => {
  return <StyledGutter {...props}>{props.children}</StyledGutter>;
};
export default Gutter;
