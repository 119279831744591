import Chessboard from "components/Game/Chessboard";
import GameInfo from "components/Game/GameInfo";
import Overlay from "components/Overlay";
import { useReducer, useState } from "react";
import { action, GameContext, GameDispatchContext, gameReducer, initialState } from "state/GameState";
import Styled from "styled-components";
import Toolbar from "./Toolbar";

const Grid = Styled.div`
  display: grid;
  min-height: 100vh;
  min-width: var(--board-size);
  //width: calc(240px + var(--board-size) + 240px);
  //grid-template-columns: 240px var(--board-size) 240px;
  grid-template-columns: 1fr var(--board-size) 1fr;
  grid-template-rows: var(--header-height) 1fr;
  justify-content: center;
  align-items: start;
  grid-template-areas:
    "header header header"
    "left_sidebar main right_sidebar";

    // on small screens we want the chessboard to be full width
  @media (max-width: calc(240px + min(calc(100vh - 64px), 100vw ) + 240px)) { //can't use --board-size here as media queries cannot use css variables
    grid-template-columns: 1fr;
    grid-template-rows: var(--header-height) var(--board-size) min-content auto;
    grid-template-areas:
    "header"
     "main"
     "left_sidebar"
     "right_sidebar";
  }
  `;

const Layout = ({ children }) => {
  const [isBlackPOV, setIsBlackPOV] = useState(initialState.playerColour === "black");
  const [autoPlay, setAutoPlay] = useState(false);
  const [game, dispatch] = useReducer(gameReducer, initialState);
  const handleNewGame = () => {
    dispatch({ type: action.RESET });
  };
  const handleTakeBack = () => {
    dispatch({ type: action.TAKE_BACK });
  };
  return (
    <Grid>
      <Toolbar style={{ gridArea: "header" }} />
      <GameContext.Provider value={game}>
        <GameDispatchContext.Provider value={dispatch}>
          <Chessboard style={{ gridArea: "main" }} blackPOV={isBlackPOV} autoPlay={autoPlay} />
          <GameInfo style={{ gridArea: "right_sidebar" }} />
          <div
            style={{
              gridArea: "left_sidebar",
              alignSelf: "center",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              color: "white",
            }}
          >
            <div>
              <input
                style={{ width: "200px" }}
                type="range"
                id="difficulty"
                name="difficulty"
                min="0"
                max="4"
                value={game.difficulty}
                onChange={(e) => {
                  dispatch({ type: action.SET_DIFFICULTY, difficulty: e.target.value });
                }}
              />
              <br />
              <label htmlFor="difficulty">
                Difficulty: {["Easy", "Beginner", "Intermediate", "Advanced", "Expert"][game.difficulty]}
              </label>
            </div>
            <button style={{ width: "200px" }} onClick={() => setIsBlackPOV(!isBlackPOV)}>
              Flip Board
            </button>
            <button
              style={{ width: "200px" }}
              onClick={() => {
                const newColour = game.playerColour === "white" ? "black" : "white";
                setIsBlackPOV(newColour === "black");
                dispatch({ type: action.SET_PLAYER_COLOUR, playerColour: newColour });
              }}
            >
              Swap sides (playing {game.playerColour})
            </button>
            <button style={{ width: "200px" }} onClick={() => setAutoPlay(!autoPlay)}>
              Autoplay ({autoPlay ? "on" : "off"})
            </button>
            <button style={{ width: "200px" }} onClick={handleTakeBack} disabled={!game.lastMove}>
              Take back move
            </button>
            <button style={{ width: "200px" }} onClick={handleNewGame}>
              New game
            </button>
          </div>
          {game.activeStates.game_over && (
            <Overlay>{game.activeStates.checkmate ? "Checkmate!" : "Stalemate!"}</Overlay>
          )}
        </GameDispatchContext.Provider>
      </GameContext.Provider>
    </Grid>
  );
};

export default Layout;
