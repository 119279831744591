import bB from "./bb.png";
import bK from "./bk.png";
import bN from "./bn.png";
import bP from "./bp.png";
import bQ from "./bq.png";
import bR from "./br.png";

import wB from "./wb.png";
import wK from "./wk.png";
import wN from "./wn.png";
import wP from "./wp.png";
import wQ from "./wq.png";
import wR from "./wr.png";

const neo = {
  bP,
  bB,
  bN,
  bR,
  bQ,
  bK,
  wP,
  wB,
  wN,
  wR,
  wQ,
  wK,
};

export default neo;
