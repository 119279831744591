import styled from "styled-components";

const StyledOverlay = styled.div`
  position: fixed;
  width: 110vw;
  height: 100vh;
  top: 0;
  left: -5vw;
  z-index: 100;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 2fr;
  pointer-events: none;

  .message {
    grid-row-start: 2;
    backdrop-filter: blur(5px);
    padding: 30px;
    font-size: min(10vh, 10vw);
    background-color: rgba(0, 0, 0, 0.3);
    color: #eee;
    transform: rotate(5deg);
    pointer-events: auto;
  }
`;

const Overlay = (props) => {
  return (
    <StyledOverlay {...props}>
      <div className="message">{props.children}</div>
    </StyledOverlay>
  );
};
export default Overlay;
