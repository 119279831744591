// import light_texture from "assets/boards/maple.jpg";
// import dark_texture from "assets/boards/oak.jpg";
// background-image: ${(props) => (props.color === "white" ? `url(${light_texture})` : `url(${dark_texture})`)};
import styled from "styled-components";

const StyledSquare = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  grid-area: ${(props) => props.gridArea};
  background-color: ${(props) => (props.color === "white" ? "rgba(238, 238, 238, 0.9)" : "rgba(111, 133, 183, 0.9)")};
  background-color: ${(props) => (props.color === "white" ? "rgba(238, 238, 238, 0.9)" : "black")};
  z-index: ${(props) => (props.color === "white" ? 3 : 1)};
  ${(props) => props.isSelected && "box-shadow: inset 0 0 0 5px rgba(146, 211, 167,1)"};

  /* create color overlay for selected cells */
  ${(props) =>
    props.isHighlighted &&
    `&:after {
      content: "";
      top: 0;
      left; 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(146, 211, 167, 0.4);
      z-index: 1;
    }`};

  /* create color overlay for moved cells */
  ${(props) =>
    props.isMovedFrom &&
    `&:before {
        content: "";
        top: 0;
        left; 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(255, 64, 112, 0.3);
        z-index: 1;
      }`};
  ${(props) =>
    props.isMovedTo &&
    `&:before {
          content: "";
          top: 0;
          left; 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(255, 64, 112, 0.5);
          z-index: 1;
        }`};
`;
const Square = (props) => {
  return <StyledSquare {...props}>{props.children}</StyledSquare>;
};

export default Square;
