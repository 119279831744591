import { useContext, useEffect } from "react";
import { action, GameContext, GameDispatchContext } from "state/GameState";
import styled from "styled-components";
import Gutter from "./Gutter";
import Piece from "./Piece";
import Square from "./Square";

const FILES = "abcdefgh";
let Board = styled.div`
  --gutter-size: 24px;
  --margin-size: 12px;
  margin-top: var(--margin-size);
  align-self: start;
  justify-self: center;
  position: relative;
  overflow: hidden;
  width: calc(var(--board-size) - var(--margin-size) * 2);
  height: calc(var(--board-size) - var(--margin-size) * 2);
  display: grid;

  // aspect-ratio: 1 / 1;
  grid-template-columns: var(--gutter-size) repeat(8, 1fr) var(--gutter-size);
  grid-template-rows: var(--gutter-size) repeat(8, 1fr) var(--gutter-size);
  grid-template-areas:
    "gutter_top_left gutter_top_a gutter_top_b gutter_top_c gutter_top_d gutter_top_e gutter_top_f gutter_top_g gutter_top_h gutter_top_right"
    "gutter_left_8 a8 b8 c8 d8 e8 f8 g8 h8 gutter_right_8"
    "gutter_left_7 a7 b7 c7 d7 e7 f7 g7 h7 gutter_right_7"
    "gutter_left_6 a6 b6 c6 d6 e6 f6 g6 h6 gutter_right_6"
    "gutter_left_5 a5 b5 c5 d5 e5 f5 g5 h5 gutter_right_5"
    "gutter_left_4 a4 b4 c4 d4 e4 f4 g4 h4 gutter_right_4"
    "gutter_left_3 a3 b3 c3 d3 e3 f3 g3 h3 gutter_right_3"
    "gutter_left_2 a2 b2 c2 d2 e2 f2 g2 h2 gutter_right_2"
    "gutter_left_1 a1 b1 c1 d1 e1 f1 g1 h1 gutter_right_1"
    "gutter_bottom_left gutter_bottom_a gutter_bottom_b gutter_bottom_c gutter_bottom_d gutter_bottom_e gutter_bottom_f gutter_bottom_g gutter_bottom_h gutter_bottom_right";
  ${(props) =>
    props.blackPOV &&
    `grid-template-areas:
       "gutter_top_left gutter_top_h gutter_top_g gutter_top_f gutter_top_e gutter_top_d gutter_top_c gutter_top_b gutter_top_a gutter_top_right"
       "gutter_left_1 h1 g1 f1 e1 d1 c1 b1 a1 gutter_right_1"
       "gutter_left_2 h2 g2 f2 e2 d2 c2 b2 a2 gutter_right_2"
       "gutter_left_3 h3 g3 f3 e3 d3 c3 b3 a3 gutter_right_3"
       "gutter_left_4 h4 g4 f4 e4 d4 c4 b4 a4 gutter_right_4"
       "gutter_left_5 h5 g5 f5 e5 d5 c5 b5 a5 gutter_right_5"
       "gutter_left_6 h6 g6 f6 e6 d6 c6 b6 a6 gutter_right_6"
       "gutter_left_7 h7 g7 f7 e7 d7 c7 b7 a7 gutter_right_7"
       "gutter_left_8 h8 g8 f8 e8 d8 c8 b8 a8 gutter_right_8"
       "gutter_bottom_left gutter_bottom_h gutter_bottom_g gutter_bottom_f gutter_bottom_e gutter_bottom_d gutter_bottom_c gutter_bottom_b gutter_bottom_a gutter_bottom_right";
       ;`}

  @media (max-width: calc(240px + min(calc(100vh - 64px), 100vw ) + 240px)) {
    //can't use --board-size here as media queries cannot use css variables
    grid-template-columns: 0 repeat(8, 1fr) 0;
    grid-template-rows: 0 repeat(8, 1fr) 0;
    overflow: hidden;
    border: solid 2px rgba(238, 238, 238, 0.9);
    margin-top: 0;
    align-self: center;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: linear-gradient(165deg, rgba(49, 85, 124, 1) 0%, rgba(106, 144, 166, 1) 100%);
    mix-blend-mode: screen;
    pointer-events: none;
  }
`;

const Chessboard = (props) => {
  const game = useContext(GameContext);
  const dispatch = useContext(GameDispatchContext);

  useEffect(() => {
    if (!game.activeStates.game_over && (props.autoPlay || game.turn !== game.playerColour)) {
      fetch(`/api/nextmove`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fen: game.fen, elo: 1500, difficulty: game.difficulty }),
      })
        .then((res) => res.json())
        .then((data) => {
          setTimeout(() => {
            dispatch({ type: action.MOVE, move: data.move });
          }, 500);
        })
        .catch((err) => {});
    }
  });

  const selectSquare = (position) => {
    const piece = game.getPosition(position);
    //if (piece && piece.color === game.playerColour[0]) {
    // playerColour 'white', piece color 'w'
    if (piece && piece.color === game.turn[0]) {
      dispatch({ type: action.SELECT_POSITION, position });
    } else {
      dispatch({ type: action.SELECT_POSITION, position: null });
    }
  };
  const handlePointerDown = (e, position) => {
    e.stopPropagation();
    console.log(`Down at ${position}`);

    if (!game.selectedPosition) {
      selectSquare(position);
    } else if (game.selectedPosition === position) {
      selectSquare(null);
    } else {
      let move = game.validMoves.find((move) => move.from === game.selectedPosition && move.to === position);
      if (move) {
        dispatch({ type: action.MOVE, move });
      } else {
        selectSquare(position);
      }
    }
  };
  const handlePointerUp = (e, position) => {
    // setSelectedPosition(selectedPosition === position ? null : position);
    // e.stopPropagation();
    // console.log(`Up at ${position}`);
  };
  const handlePointerOver = (e, position) => {
    // e.stopPropagation();
    // console.log(`Over at ${position}`);
  };

  let gutters = [];
  let squares = [];
  let pieces = [];

  for (let rank = 8; rank > 0; rank--) {
    gutters.push(
      <Gutter position="left" gridArea={`gutter_left_${rank}`} key={`gutter_left_${rank}`}>
        {rank}
      </Gutter>
    );
    gutters.push(
      <Gutter position="right" gridArea={`gutter_right_${rank}`} key={`gutter_right_${rank}`}>
        {rank}
      </Gutter>
    );
  }
  for (let file = 0; file < 8; file++) {
    gutters.push(
      <Gutter position="top" key={`gutter_top_${FILES[file]}`} gridArea={`gutter_top_${FILES[file]}`}>
        {FILES[file]}
      </Gutter>
    );
    gutters.push(
      <Gutter position="bottom" key={`gutter_bottom_${FILES[file]}`} gridArea={`gutter_bottom_${FILES[file]}`}>
        {FILES[file]}
      </Gutter>
    );
  }
  gutters.push(<Gutter position="top left" key={`gutter_top_left`} gridArea={`gutter_top_left`} />);
  gutters.push(<Gutter position="bottom left" key={`gutter_bottom_left`} gridArea={`gutter_bottom_left`} />);
  gutters.push(<Gutter position="top right" key={`gutter_top_right`} gridArea={`gutter_top_right`} />);
  gutters.push(<Gutter position="bottom right" key={`gutter_bottom_right`} gridArea={`gutter_bottom_right`} />);

  let currentColor = "white";
  for (let rank = 8; rank > 0; rank--) {
    currentColor = rank % 2 === 0 ? "white" : "black";
    for (let file = 0; file < 8; file++) {
      let position = `${FILES[file]}${rank}`;
      let piece = game.board[8 - rank][file];
      if (piece) {
        pieces.push(<Piece gridArea={position} color={piece.color} type={piece.type} key={position} />);
      }
      squares.push(
        <Square
          gridArea={position}
          color={currentColor}
          key={position}
          title={position}
          isHighlighted={game.highlightedPositions.includes(position)}
          isSelected={game.selectedPosition === position}
          isMovedFrom={game.lastMove && game.lastMove.from === position}
          isMovedTo={game.lastMove && game.lastMove.to === position}
          onPointerDown={(e) => {
            handlePointerDown(e, position);
          }}
          onPointerOver={(e) => {
            handlePointerOver(e, position);
          }}
          onPointerUp={(e) => {
            handlePointerUp(e, position);
          }}
        ></Square>
      );
      currentColor = currentColor === "white" ? "black" : "white";
    }
  }

  return (
    <Board {...props}>
      {gutters}
      {squares}
      {pieces}
    </Board>
  );
};
export default Chessboard;
