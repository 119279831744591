import logo from "assets/logo.svg";
import Styled from "styled-components";

const StyledToolBar = Styled.header`
  display: flex;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #101B21;
  color: #fff;

  // font-weight: lighter;
  font-size: 24px;

  a {
    color: #fff;
    text-decoration: none;

    :hover {
      color: #92d3a7;
    }
  }

  padding: 6px;
  ul {
    display flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 6px;
    }
  }
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
    font-size: 32px;
    img {
      height: 52px;
      width: 52px;
      margin-right: 8px;
    }
  }
`;

const ToolBar = (props) => {
  return (
    <StyledToolBar style={props.style}>
      <a className="logo" href="/">
        <img src={logo} alt="logo" />
        <span>Kings</span>
      </a>
      <nav>
        <ul>
          <li>
            <a href="/">Play</a>
          </li>
          <li>
            <a href="/">History</a>
          </li>
          <li>
            <a href="/">About</a>
          </li>
        </ul>
      </nav>
      <div>
        <span>Teon Harasymiv</span>
      </div>
    </StyledToolBar>
  );
};

export default ToolBar;
